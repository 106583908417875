//import {ControlValueAccessor} from '@angular/forms';

import { ControlValueAccessor } from "@angular/forms";
export { ControlValueAccessor };


export class ValueAccessorBase<T> implements ControlValueAccessor {  
  private innerValue: T;
  private changed = new Array<(value: T) => void>();
  private touched = new Array<() => void>();
  private inputValueChanged = new Array<(value: T) => void>();
  public isDisabled: boolean = false;
  
  get value(): T {
    return this.innerValue;
  }


  set value(value: T) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.changed.forEach(f => f(value));
    }
  }

  touch() {
    this.touched.forEach(f => f());
  }

  writeValue(value: T) {
    this.innerValue = value;
    this.inputValueChanged.forEach(f => f(value));
  }

  registerOnInputChange(fn: (value: T) => void) {
    this.inputValueChanged.push(fn);
  }

  registerOnChange(fn: (value: T) => void) {
    this.changed.push(fn);
  }


  registerOnTouched(fn: () => void) {
    this.touched.push(fn);
  }

  setDisabledState(isDisabled: boolean): void{
    this.isDisabled = isDisabled;
  }

 
}
/*
export interface ControlValueAccessor {
  writeValue(obj: any): void
  registerOnChange(fn: any): void
  registerOnTouched(fn: any): void
  setDisabledState(isDisabled: boolean): void
}
*/